<template>
  <b-card no-body class="overflow-hidden shadow-sm">
    <b-row no-gutters>
      <b-col sm="3">
        <b-card-img-lazy
          :src="thumbnails[600]"
          :alt="name"
          class="rounded-0"
        ></b-card-img-lazy>
      </b-col>

      <b-col sm="8">
        <b-card-body>
          <b-card-title title-tag="h4">{{ resumeEpisodeTitle }}</b-card-title>

          <h6>
            ({{ program.publisher || program.copyright }})
            {{ resumeProgramTitle }}
          </h6>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <b-card-text v-bind="attrs" v-on="on" class="text-justify my-1">
                {{ resumeEpisodeDescription }}
              </b-card-text>
            </template>
            <span>{{ description }}</span>
          </v-tooltip>

          <h6>Categories : {{ resumeEpisodeCategories }}</h6>
          <h6>Keywords : {{ resumeEpisodeKeywords }}</h6>
        </b-card-body>
      </b-col>

      <b-col sm="1" class="text-center">
        <b-row class="m-auto">
          <b-col>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <b-badge v-bind="attrs" v-on="on" variant="info">{{
                  score
                }}</b-badge>
              </template>
              <span>Score : {{ score }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="ma-2 center"
                  color="#4540C9"
                  :href="URL"
                  target="_blank"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
              </template>
              <span>Go to listen : {{ URL }}</span>
            </v-tooltip>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  getRandomInt,
  stripedExcerpt,
  extractExcerpt
} from "@/common/config/discovery";

export default {
  name: "RecommendEpisodeCard",

  components: {},

  props: {
    name: {
      type: String,
      require: true
    },
    description: {
      type: String,
      require: true
    },
    URL: {
      type: String,
      require: true
    },
    program: {
      type: Object,
      require: true
    },
    thumbnails: {
      type: Object,
      require: true
    },
    score: {
      type: Number,
      require: false,
      default: () => getRandomInt(100)
    }
  },

  computed: {
    resumeProgramTitle() {
      return stripedExcerpt(this.program.name.trim(), 100);
    },
    resumeEpisodeTitle() {
      return stripedExcerpt(this.name.trim(), 100);
    },
    resumeEpisodeDescription() {
      return stripedExcerpt(this.description.trim(), 200);
    },
    resumeEpisodeCategories() {
      return extractExcerpt(this.program.categories, 100);
    },
    resumeEpisodeKeywords() {
      return extractExcerpt(this.program.keywords, 100);
    }
  }
};
</script>
