<template>
  <div>
    <SubHeaderDiscovery />

    <AlertExpirePassword />

    <b-container class="kt-container" fluid>
      <b-row>
        <b-col sm="12" class="mt-sm-5 mt-md-0" v-if="!isAdmin">
          <h2 class="text-left pl-5">Forbidden</h2>
        </b-col>

        <b-col sm="12" class="mt-sm-5 mt-md-0" v-if="isAdmin">
          <b-card
            border-variant="secondary"
            header-border-variant="secondary"
            style="min-height: 800px"
          >
            <b-form @submit="onSubmit" role="group">
              <b-row>
                <b-col v-if="isCompanyListVisible" sm="3" md="3">
                  <FilterSelect
                    value-field="id"
                    text-field="name"
                    property="Companies"
                    :limit="itemPerPage"
                    :multiSelect="false"
                    :fetch-items="fetchCompanies"
                    @update:selected="handleFilterSelectSelectedUpdate"
                  />
                </b-col>

                <b-col
                  :sm="isCompanyListVisible ? 9 : 12"
                  :md="isCompanyListVisible ? 7 : 10"
                >
                  <b-form-input
                    id="input-url"
                    size="sm"
                    v-model="form.pageURL"
                    placeholder="URL"
                    @change="urlInputChangeHandler"
                  ></b-form-input>
                </b-col>

                <b-col sm="12" md="2" class="align-middle text-center">
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mx-1 w-100"
                    :disabled="isButtonDisabled"
                    >Submit
                  </b-button>
                </b-col>
              </b-row>
            </b-form>

            <b-row>
              <b-col sm="12" class="mt-sm-5">
                <v-progress-linear
                  v-if="isLoading"
                  indeterminate
                  color="info"
                ></v-progress-linear>

                <div v-if="isPageDetailsVisible">
                  <RecommendPageCard :payload="payload"></RecommendPageCard>
                </div>

                <div v-if="isEpisodesVisible" class="mt-5">
                  <div
                    v-for="(episode, index) in episodes"
                    :key="index"
                    class="card drag mb-3"
                  >
                    <RecommendEpisodeCard v-bind="episode">
                    </RecommendEpisodeCard>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import axios from "axios";
import { recommendURL, getPlaylist } from "@/api/discovery";
import { getCompaniesV2 as getCompanies } from "@/api/companies/getters";
import { defaultPayload } from "@/common/config/discovery";
import RecommendEpisodeCard from "@/views/partials/content/cards/RecommendEpisodeCard.vue";
import RecommendPageCard from "@/views/partials/content/cards/RecommendPageCard.vue";
import SubHeaderDiscovery from "@/components/subheaders/SubHeaderDiscovery";
import AlertExpirePassword from "@/components/AlertExpirePassword";
import FilterSelect from "./filters/FilterSelect.vue";

import {
  itemPerPagecompanies,
  defaultPaginationResponse
} from "./filters/configuration";

export default {
  name: "Recommendation",

  components: {
    RecommendEpisodeCard,
    RecommendPageCard,
    SubHeaderDiscovery,
    AlertExpirePassword,
    FilterSelect
  },

  data() {
    return {
      form: {
        pageURL: "",
        company: null
      },
      episodes: [],
      payload: defaultPayload,
      isLoading: false
    };
  },

  async created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Discovery",
        pageCategory: "Simulator"
      }
    ]);

    if (this.$route?.query?.page_url) {
      this.form.pageURL = this.$route.query.page_url;
    }

    if (this.$route?.query?.company_id) {
      this.form.company = this.$route.query.company_id;
    }

    if (this.form.pageURL && this.form.company) {
      this.onSubmit();
    }
  },

  computed: {
    ...mapGetters(["isAdmin", "currentUser", "isPaidCustomer"]),

    isButtonDisabled() {
      return this.form.pageURL.trim() === "";
    },

    isPageDetailsVisible() {
      return (
        !this.isLoading &&
        this.payload.page.currentURL.trim() !== "" &&
        !this.isButtonDisabled
      );
    },

    isEpisodesVisible() {
      return (
        !this.isLoading && this.episodes.length > 0 && !this.isButtonDisabled
      );
    },

    isCompanyListVisible() {
      return this.isAdmin;
    },

    itemPerPage() {
      return itemPerPagecompanies;
    }
  },

  methods: {
    urlInputChangeHandler() {
      this.isLoading = false;
      this.episodes = [];
      this.payload = defaultPayload;
    },

    handleFilterSelectSelectedUpdate(selectedItem) {
      this.form.company = selectedItem?.id || undefined;
      this.isLoading = false;
      this.episodes = [];
      this.payload = defaultPayload;
    },

    async fetchCompanies(search, page, limit) {
      const companies = await getCompanies(axios, {
        search,
        page,
        limit
      });

      if (typeof companies === "object" && "data" in companies) {
        return companies;
      }

      return defaultPaginationResponse;
    },

    onSubmit(evt) {
      if (evt) {
        evt.preventDefault();
      }

      this.isLoading = true;
      this.episodes = [];

      let company = "no_company";
      if (this.isAdmin) {
        company = this.form.company;
      } else if (
        this.isPaidCustomer &&
        this.currentUser.company &&
        this.currentUser.company?.externalId
      ) {
        company = this.currentUser.company?.externalId;
      }

      recommendURL(axios, {
        url: encodeURI(this.form.pageURL.trim()),
        company_id: company
      })
        .then(response => {
          if (!("playlist" in response) || !("id" in response.playlist)) {
            throw new Error("no playlist");
          }
          this.payload = response.payload;
          return response.playlist;
        })
        .then(({ id }) => getPlaylist(axios, id))
        .then(response => {
          if (!("episodes" in response)) {
            throw new Error("no episode in playlist");
          }
          this.episodes = response.episodes;
        })
        .catch(error => console.error(error))
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>
