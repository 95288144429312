export const defaultPayload = {
  browser: {
    language: ""
  },
  page: {
    currentURL: "",
    location: "",
    propertyURLUsed: "window.location.href",
    referrer: "",
    lang: "fr",
    title: "",
    description: "",
    keywords: "",
    additional: {
      "article:tag": "",
      "article:section": ""
    }
  }
};

export const excerpt = (content, max) => {
  if (content.length < max) {
    return content;
  }
  return content.slice(0, max);
};

export const striped = content => {
  return content ? content.replace(/<[^>]+>/g, " ").trim() : "";
};

export const stripedExcerpt = (content, max) => {
  let stripedHTML = striped(content);
  if (stripedHTML.length <= max) {
    return stripedHTML;
  }
  stripedHTML = excerpt(stripedHTML, max);
  return stripedHTML.substring(0, stripedHTML.lastIndexOf(" ") + 1) + "...";
};

export const extractExcerpt = (property, max) => {
  let content = "";
  if (Array.isArray(property)) {
    content = property.join(", ");
  } else if (typeof property === "string") {
    content = property.trim();
  }

  if (content.length > 0) {
    return stripedExcerpt(content, max);
  }
  return "-";
};

export const getRandomInt = max => {
  return Math.floor(Math.random() * max) / 100;
};
