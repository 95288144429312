<template>
  <b-card no-body class="overflow-hidden shadow-md">
    <b-row no-gutters>
      <b-col sm="12">
        <b-card-body>
          <b-card-title title-tag="h1">Page details</b-card-title>

          <b-card-text>
            <strong>Title :</strong>
            {{ payload.page.title }}</b-card-text
          >
          <b-card-text
            ><strong>Description :</strong>
            {{ payload.page.description }}</b-card-text
          >
          <b-card-text>
            <strong>Keywords :</strong>
            {{ payload.page?.keywords || "-" }}
          </b-card-text>
          <b-card-text
            ><strong>Site lang :</strong>
            {{ payload.page?.lang || "-" }}</b-card-text
          >
          <b-card-text>
            <strong>Article > section :</strong>
            {{ payload.page?.additional["article:section"] || "-" }}
          </b-card-text>
          <b-card-text>
            <strong>Article > tag :</strong>
            {{ payload.page?.additional["article:tag"] || "-" }}
          </b-card-text>
          <b-card-text>
            <strong> News keywords :</strong>
            {{ payload.page?.additional["news_keywords"] || "-" }}
          </b-card-text>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "RecommendPageCard",

  props: {
    payload: {
      type: Object,
      require: true
    }
  }
};
</script>
